// The utilities files to store functions that would be used in mutiple components.
import parse from 'html-react-parser'



function findKey(obj, targetKey, flexible = false) {
  let result = [];

  function search(current) {
      // console.log("performing search, flexible is " + flexible);
      if (typeof current === 'object' && current !== null) {
          let regex = null;
          if (Array.isArray(current)) {
              // If the current item is an array, iterate through it
              current.forEach(item => search(item));
          } else {
              // If the current item is an object, check each key
              for (const key in current) {
                  if (flexible) {
                    // Escape special regex characters in the URL except for the numeric part
                    const escapedUrl = key.replace(/[*+?^${}()|[\]\\]/g, '\\$&');
                    // Replace the numeric version part with a regex pattern that matches any version
                    const versionedUrl = escapedUrl.replace(/(\d+)\.(\d+)/, '\\d+\\.\\d+');
                    // Construct the final regex
                    const regexPattern = `^${versionedUrl}$`;
                    regex = new RegExp(regexPattern, "i");
                    // console.log("Just created regex " + regex + " for flexible searching");
                  }
                  if (key === targetKey) {
                      result.push(current[key]);
                  } else if (flexible) {
                      // console.log("Going to test if key " + targetKey + " matches regex " + regex);
                      if (regex.test(targetKey)) {
                        // console.log("Didn't find an exact match, but matched a regex");
                        result.push(current[key]);
                      }
                  }
                  // Recursively search the value associated with the key
                  search(current[key]);
              }
          }
      }
  }

  search(obj);
  return result;
}


// functional components to highlight search text and covert indentifers to the links
// same function from iSamples_results.js
export function ResultWrapper(props) {
  const { field, value } = props
  let text = [].concat(value || null).filter((v) => v !== null).join(", ");

  if (field.type === 'text' && field.value !== undefined) {
    // replace "&", "|", "(", ")", "*", "'", """ and duplicated whitespace with only one whitespace
    let fieldValue = field.value;
    if (field.field === "id" && value !== undefined){
      fieldValue = field.value.replace("\\","")
    }
    const values = fieldValue.replaceAll(/&|\*|\(|\)|\||"|'/g, "").replaceAll(/\s+/g, " ").split(" ");

    // split original text by search words insensitively by regex pattern
    // g is for regex global and i is for insensitive.
    values.map((value) => {
      const regex = new RegExp(value, "gi");
      text = text.split(regex).join("<span style='background-color:yellow;'>" + value + "</span>");
      return text;
    })
  }
  let result = parse(text);
  if (field.field === "id") {
    result = <a href={window.config.original_source + "/" + value} target="_blank" rel="noopener noreferrer">{parse(text)}</a>
  } if (field.field === "hasContextCategory" || field.field === "hasSpecimenCategory" || field.field === "hasMaterialCategory") {
    let vocabulary;
    if (field.field === "hasContextCategory") {
      vocabulary = window.config.vocabularySampledFeatureType;
    } else if (field.field === "hasSpecimenCategory") {
      vocabulary = window.config.vocabularyMaterialSampleType;
    } else if (field.field === "hasMaterialCategory") {
      vocabulary = window.config.vocabularyMaterialType;
    }
    const uriPieces = text.split(", ");
    result = [];
    const seenUris = new Set();
    for (let i = 0; i < uriPieces.length; i++) {
      let text = uriPieces[i];
      if (seenUris.has(text)) {
        continue;
      } else {
        seenUris.add(text);
      }
      // console.log("looking for key in " + JSON.stringify(vocabulary, null, 2) + " key is " + text);
      let label = findKey(vocabulary, text);
      if (label === undefined || label.length === 0) {
        // If we didn't find an exact match, then try the flexible matching based on the url version
        label = findKey(vocabulary, text, true);
      }
      // console.log("have label: " + JSON.stringify(label, null, 2));
      if (label !== undefined && label.length !== 0) {
        label = label[0]["label"]["en"];
      } else {
        // console.log("Unable to find label, text is " + text);
        label = text;
      }
      result.push(<span><a href={text} target="_blank" rel="noopener noreferrer">{label}</a><span>&nbsp;&nbsp;</span></span>)
    }
}
  return result;
}

// default function to convert field names to well format one
export function wellFormatField(field) {
  let name = "";
  // check if '_' in the string
  if (field.includes('_')) {
    name = field.split('_')
  } else {
    name = field.split(/(?=[A-Z])/);
  }
  name = name.map((s) => s.charAt(0).toUpperCase() + s.slice(1))
  name = name.join(' ');
  return name
}

/**
 * a function to check if at least one field has value
 * @param {*} fields a hashtable variable
 */
export function checkAllValue(fields) {
  const values = fields.filter((field) => field.value && (Object.keys(field.value).length > 0 || field.value.length > 0));
  return values.length > 0;
}

/**
 * a function to return only fields with values
 * @param {*} fields a array of field hashtable
 * @returns
 */
export function getAllValueField(fields) {
  return fields.filter((field) => field.value && (Object.keys(field.value).length > 0 || field.value.length > 0));
}

/**
 * A funtion to force changing path after hash
 * ex, /# become
 * @param {string} path the router path
 */
export function forceSlashAfterHash(path) {
  let pathname = window.location.pathname;
  if (pathname[1] && pathname.includes(path)) {
    pathname = pathname.replace(path, "")
    window.location.href = window.location.origin + pathname + `#/${path}` + window.location.search;
  }
}

// Convert timestamp to string
export function TStoDate(timestamp) {
  if (!timestamp) {
    return "";
  }

  const date = new Date(timestamp * 1000);
  return date.toISOString();
}

